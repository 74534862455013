/******
 * UI
 **/
body {
	font-family: Roboto;
	font-size: 	14px;
}

a {
	color: #2196F3;
	text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
	font-family: Poppins;
	margin: 0;
	font-weight: 600;
}

h1 {
	font-size: 32px;
	color: #fff;
	line-height: 100%;
	max-width: 351px;
}

h2 {
	font-size: 24px;
	font-weight: 600;
}

h3.above-table {
	margin: 15px 0;

	&:not(:first-of-type) {
		margin-top: 30px;
	}
}

.green {
	color: $green;
}

.red {
	color: $red;
}

.orange {
	color: $orange;
}

input, select, textarea {
	background: #FFFFFF;
	border: 1px solid #E2E4E9;
	border-radius: 42px;
	padding: 6px 6px 6px 32px;
	outline: none;
}

textarea {
	width: 100%;
    height: 154px;
	resize: none;
	font: 400 13.3333px Arial;
}

.field {
	label {
		font-size: 12px;
		color: #666;
		display: block;
		margin-bottom: 2px;
	}
	.radio {
		label {
			font-size: inherit;
			color: inherit;
			display: inline-block;
			cursor: pointer;
		}
	}
	input, select, textarea {
		border-radius: 2px;
		padding: 6px;
		font-size: 14px;
	}

	&.medical {
		ol {
			padding-left: 30px;
		}

		.question {
			font-size: 14px;
			line-height: 22px;
			text-decoration-line: underline;
			color: #000000;
			font-weight: 500;
			margin-bottom: 12px;
		}

		label {
			font-size: 14px;
			line-height: 18px;
			color: #15303F;
			font-weight: 400;
		}

		.radio {
			margin-bottom: 18px;
		}

		li {
			padding-left: 12px;
		}

		.sub-field-medical-follow {
			margin-bottom: 24px;
		}

		& .field {
			margin-bottom: 12px;
		}
	}
}

.checkbox {
	overflow: hidden;
	position: relative;
	top: 1px;

	input {
		position: absolute;
		top: -100px;
		height: 0;
		width: 0;
	}
	span {
		cursor: pointer;
		display: inline-block;
		margin-right: 3px;
		opacity: 0.5;
		transition: 0.2s opacity;

		&:before {
			content: "\e907";
			font-family: icomoon;
		}
		&:hover {
			opacity: 1;
		}
	}
	input:checked ~ span {
		&:before {
			content: "\e908";
		}
		opacity: 1;
	}
}

.checkboxes {
	margin-top: auto;

	.checkbox {
		margin-top: 10px;
    	margin-right: 15px;

		label {
			font-family: Roboto;
    		font-size: 14px;
			color: #666;
			padding-left: 21px;

			span {
				position: absolute;
				left: 0px;
				top: 0px;

				&:before {
					color: #25598B;
				}
			}
		}
	}
}

.radio {
	padding: 6px 0;
	input {
		height: auto !important;
		width: auto !important;
		display: inline-block;
	}
	span {
		display: inline-block;
	}
}

button, .button {
	border-radius: 120px;
	padding: 7px 10px;
	background: $mediumGrey;
	border: none;
	font-weight: 500;
	color: #fff;
	cursor: pointer;
	transition: background 0.2s;
	outline: none;

	&.red {
		background: $red;
		color: #fff;
		&:hover {
			background: darken($red, 10%);
		}
	}

	&.blue {
		background: $blue;
		color: #fff;
		&:hover {
			background: darken($blue, 10%);
		}
	}

	&.transparent {
		background: transparent;
		border: 1px solid $blue;
		color: $blue;

		&:hover {
			background: rgba($blue, 0.05);
		}
	}

	&.green {
		background: $greenButton;
		color: #fff;
		&:hover {
			background: darken($greenButton, 10%);
		}
	}

	&.grey {
		background: $greyButton;
		color: #fff;
		&:hover {
			background: darken($greyButton, 10%);
		}
	}

	&.add {
		&:before {
			content: "\e924";
			font-family: icomoon;
			position: relative;
			margin-right: 5px;
			margin-left: 2px;
			top: 1px;
			font-size: 13px;
		}
	}

	&.rectangle {
		border-radius: 0;
	}
}

.table-wrapper table {
	width: 100%;
	border-collapse: collapse;

	thead {
		td {
			padding: 18px 10px;
			font-size: 14px;
			color: $mediumGrey;

			i:before {
				font-size: 16px;
				position: relative;
				top: 2px;
			}

			&.sort-icon {
				position: relative;

				span {
					position: relative;
					left: 6px;
					cursor: pointer;

					&:after {
						content: '';
						position: absolute;
						background-image: url(images/sorting-icon.png);
						background-size: contain;
						background-repeat: no-repeat;
						height: 8px;
						width: 12px;
						transform: translateY(-50%);
						top: 50%;
					}
				}
			}
		}
	}

	tbody {
		tr {
			cursor: pointer;
			border-top: 1px solid #E2E4E9;
			transition: 0.3s background-color;
			position: relative;

			&:first-child {
				border-top: none;
			}

			&:hover {
				background-color: $tableHover;
			}
		}
		td {
			padding: 14px 12px;
		}
	}

	td {
		&.left {
			text-align: left;
		}
		&.center {
			text-align: center;
		}
		&.right {
			text-align: right;
		}
		&:first-child {
			padding-left: 20px;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 200px;
		}
		&:last-child {
			padding-right: 20px;
		}
		&.red {
			color: $red;
		}
		&.green {
			color: $green;
		}

		.icon-trash {
			opacity: 0.5;
			cursor: pointer;
			transition: opacity 0.25s;
			&:hover {
				opacity: 1;
			}
		}

		&.empty-column {
			padding: 0;

			&.last-child {
				padding-right: 8px;
			}
		}

		&.buttons {
			float: left;
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 0;

			button {

				&:first-of-type {
					background-color: green;
					margin-left: auto;
				}

				&:last-of-type {
					background-color: hotpink;
					margin: 0 auto;
				}
			}
		}
	}
}

.progress {
	background: rgba($blue, 0.1);
	color: $blue;
	border-radius: 3px;
	padding: 2px 5px 2px 22px;
	display: inline-block;
	position: relative;

	i {
		position: absolute;
		top: 3px;
		left: 5px;
		font-size: 15px;
	}

	&.orange {
		background: rgba($orange, 0.1);
		color: $orange;
	}

	&.red {
		background: rgba($red, 0.1);
		color: $red;
	}
}

.clearfix {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

.empty {
	padding: 60px;
	text-align: center !important;
	color: $mediumGrey;
}

// CSS Loader
.loader {
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -40px;
	margin-left: -40px;
	width: 80px;
	height: 80px;
	
	div {
		position: absolute;
		border: 4px solid red;
		opacity: 1;
		border-radius: 50%;
		animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

		&:nth-child(2) {
			animation-delay: -0.5s;
		}
	}
}

// Audio player
.rhap_container {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	box-shadow: none;
	padding: 15px 100px;
}

.push-player {
	height: 140px;
}

.icon-play {
	font-size: 20px;
	position: relative;
	top: 3px;
	margin-top: -8px;
	display: inline-block;
}

@keyframes loader {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.custom-form {
	.fields {
		.field {
			padding-bottom: 0;
		}
	}
	.block {
		h2 {
			margin: 14px 0;
		}
	}
}

.file-handler-container {
	display: flex;
    align-items: flex-end;
	flex-wrap: wrap;
	min-height: 55px;

	p {
		margin: 0;
	}

	.image-wrap {
		display: flex;
		flex-direction: column;
		width: 212px;
		margin-right: 12px;
		height: 50px;

		&:nth-of-type(n + 5) {
			margin-top: 14px;
			margin-right: 14px;

			&.dropzone {
				margin-top: 24px;
			}
		}
	
		p {
			font-size: 12px;
	
			&.sub-title {
				opacity: 0.6;
				font-family: 'Roboto';
				margin-bottom: 4px;
			}
	
			&.image-ext-name {
				font-family: 'Inter';
			}
		}

		[class*="icon-"] {
			&:before {
				cursor: pointer;
				position: absolute;
				right: 8px;
				top: 50%;
				transform: translateY(-50%);
				color: #B2B9C6;
				font-size: 18px;
			}
		}
	
		.image-icon-wrap {
			display: flex;
			border: 1px solid #E6E7EB;
			box-sizing: border-box;
			border-radius: 4px;
			position: relative;
			height: 32px;

			p {
				align-self: center;
				margin-left: 11px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				padding-right: 35px;
			}
		}

		&.dropzone {
			background: rgba(33, 150, 243, 0.05);
			border: 1px dashed rgba(33, 150, 243, 0.5);
			cursor: pointer;
			height: 32px;
			align-self: flex-end;
			justify-content: center;
			padding-left: 13px;
			margin-right: 12px;

			.image-icon-wrap {
				border-width: 0px;
				position: relative;

				p {
					color: #2196F3;
					font-family: 'Roboto';
				}

				&[class*="icon-"] {
					&:before {
						color:#11ADE4;
					}
				}
			}
		}
	
		img{
			width: 50px;
			height: 30px;
			object-fit: cover;
		}
	}

	&.non-image {
		flex-wrap: nowrap;
		.field {
			padding-bottom: 0;
			width: auto;

			&.file-name {
				width: 38%;
			}
		}
	}
}

.editor-wrapper {
	border: 1px solid #E6E7EB;

	.rdw-editor-toolbar {
		display: none;
	}
	
	.editor-container {
		height: 100px;
		padding: 4px 20px 0 10px
	}
}

.select-custom {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		background-image: url(images/select.png);
		right: 8px;
		height: 9px;
		width: 10px;
		background-size: contain;
		background-repeat: no-repeat;
		top: 53%;
		transform: translateY(-50%);
	}
	select {
		display: flex;
		color: white;
		padding: 0;
		width: 100%;
		border-width: 0px;
		height: 18px;
		padding: 2px 6px;
		align-items: flex-start;
		font-size: 12px;
		border-radius: 9px;
		font-weight: 700;
		appearance: none;
	}

	&.red {
		width: 63px;

		select {
			background-color: #FF4646;
		}
	}

	&.yellow {
		width: 119px;

		select {
			background-color: #F2B517;
		}
	}

	&.blue {
		width: 165px;

		select {
			background-color: #2196F3;
		}
	}
	

	&.green {		
		width: 81px;

		select {
			background-color: #17B714;
		}
	}

	&.grey {
		width: 108px;

		select {
			background-color: #ACACAC;
		}
	}
	

	select i {
		&[class*="icon-"] {
			&:before {
				cursor: pointer;
				position: absolute;
				right: 3px;
				top: 50%;
				transform: translateY(-50%);
				color: white;
			}
		}
	}
}

.edit-link {
	color: #2196F3;
	text-decoration: underline;
	font-weight: 500;
	font-size: 14px;
	margin: 0;
	text-align: right;

	&:hover {
		text-decoration: none;
	}
}

.edit-delete-column {
	display: inline-flex;
    width: 100%;
    justify-content: flex-end;

	.edit-link {
		padding-right: 20px;
	}
}

.button.question {
	margin-bottom: 30px;
	width: 130px;
	text-align: center;
}

form.questions {
	.block {
		padding-bottom: 48px;
	}
}

.actions.below-form {
	position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 247px;
}